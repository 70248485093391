import ReactGA from 'react-ga4';

const useAnalyticsEventTracker = (category = 'W Doha Category') => {
    const eventTracker = (action = 'no action', label = '', params = {}) => {
        const hotelID = localStorage.getItem('__hotelID');
        ReactGA.set({ hotel_id: hotelID });
        ReactGA.event({ category, action, label });
        window.gtag(action, label, {
            hotel_id: hotelID,
            ...params,
        });
    };
    return eventTracker;
};
export default useAnalyticsEventTracker;
