import React from 'react';
import { useNavigate } from 'react-router-dom';

import { themeColors } from '../theme';
import AddressList from '../components/AddressList';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLanguage } from '../context/LanguageContext';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const ChangeAddress = () => {
    const navigate = useNavigate();
    const { translation } = useLanguage();
    const logGAEvent = useAnalyticsEventTracker('change_address');

    return (
        <div
            className='flex flex-1 flex-col'
            style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
        >
            <div className='sticky top-0 w-full flex flex-row justify-between items-center px-4 py-2 z-10 bg-white shadow'>
                <div
                    className='flex bg-gray-50 rounded-full p-2 shadow-lg'
                    style={{ backgroundColor: themeColors.primaryColor }}
                    onClick={() => {
                        logGAEvent('change_address_back_button_click', '');
                        navigate(-1);
                    }}
                >
                    <ArrowLeftOutlined style={{ color: '#fff' }} />
                </div>
                <div className='text-black text-lg font-medium ml-8'>
                    {translation['your_addresses']}
                </div>
                <Button
                    className='flex h-7 rounded-full justify-center items-center px-3 py-0.5 text-white text-xs'
                    onClick={() => {
                        logGAEvent(
                            'add_new_address_btn_clicked_from_change_address',
                            ''
                        );
                        navigate('/addNewaddress');
                    }}
                    style={{ backgroundColor: themeColors.primaryColor }}
                >
                    {translation['add_new_address']}
                </Button>
            </div>
            <div className='flex flex-1 flex-col justify-start items-start pb-8'>
                <div className='flex w-full flex-1 relative px-4 pb-0 pt-2 flex-col'>
                    <AddressList />
                </div>
            </div>
        </div>
    );
};

export default ChangeAddress;
