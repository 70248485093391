import React, { useEffect, useState } from 'react';

import { themeColors } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCategorywithItems,
    hotelSelector,
} from '../store/slices/hotel/hotelSlice';
import CategoryCard from '../components/CategoryCard';
import { getItemsAndTotal, renderCurrency } from '../services/commonHelper';
import { useAuth } from '../context/AuthContext';
import { CART_ITEMS, LAST_PAGE_VISIT } from '../services/Typecode';
import ContentLoader from 'react-content-loader';

import {
    addLocalCartItem,
    generateCartItem,
    initializeMenuItems,
    removeLocalCartItem,
    updateCartItem,
} from '../services/cartHelper';
import AddonComponent from '../components/AddonComponent';
import { useLanguage } from '../context/LanguageContext';
import AddonRepeatConfirm from '../components/AddonRepeatConfirm';
import {
    ArrowLeftOutlined,
    BarsOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Skeleton } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DisclaimerComponent from '../components/DisclaimerComponent';
import { SERVICETYPE } from '../services/Typecode';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const OrderMenuScreen = () => {
    const [localCart, setLocalCart] = useState([]);
    const [selectedMenuItem, setselectedMenuItem] = useState(null);
    const [selectedCartItemID, setselectedCartItemID] = useState(null);
    const [localCategoryItems, setLocalCategoryItems] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    // addon confirmation item
    const [addonConfirmItem, setAddonConfirmItem] = useState({});
    const [total, setTotal] = React.useState(0);

    const [addonModal, setAddonModal] = useState(false);
    const [addonRepeatModal, setAddonRepeatModal] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const { loading, categorywithItems, existingCartItems, error } =
        useSelector(hotelSelector);

    const { translation, lang } = useLanguage();
    const dispatch = useDispatch();
    const { authState } = useAuth();
    const [searchParams] = useSearchParams();
    const menuID = searchParams.get('menuid');

    const navigate = useNavigate();
    const serviceType = localStorage.getItem(SERVICETYPE);
    const logGAEvent=useAnalyticsEventTracker('OrderMenuScreen');

    useEffect(() => {
        // removeData(CART_ITEMS)
        let viewOnly = localStorage.getItem('isViewOnly');
        let checkView = viewOnly ? JSON.parse(viewOnly) : false;
        setIsViewOnly(checkView);
    }, []);

    useEffect(() => {
        updateLocalCartItems();
    }, []);

    useEffect(() => {
        if (menuID) {
            dispatch(fetchCategorywithItems(menuID, lang));
        }
    }, [menuID]);

    useEffect(() => {
        if (categorywithItems.length) {
            let oldItemsFromCart = [];
            if (existingCartItems && existingCartItems.length) {
                oldItemsFromCart = JSON.parse(
                    JSON.stringify(existingCartItems)
                );
            }
            const initiliazedDataItems = initializeMenuItems(
                JSON.parse(JSON.stringify(categorywithItems)),
                oldItemsFromCart
            );
            setLocalCategoryItems(initiliazedDataItems);
            setSelectedIndex(categorywithItems[0]?.id);
        }
    }, [categorywithItems, existingCartItems]);

    // bottom sheeet

    const closeAddonSheet = () => {
        setAddonModal(false);
        updateLocalCartItems();
    };
    const closeAddonRepeatSheet = () => {
        setAddonRepeatModal(false);
        updateLocalCartItems();
    };

    const handleCategoryClick = (id) => {
        setSelectedIndex(id);
        const categoryName = localCategoryItems.find(item => item.id === id)?.name || 'Unknown Category';
        logGAEvent('Most_popular_category', id);
        const node = document.getElementById(`category-${id}`);
        var headerOffset = 170;
        var elementPosition = node.getBoundingClientRect().top;
        var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });

        // const nodeHorizontalCategory = document.getElementById(`top-scroll-${id}`);
        // nodeHorizontalCategory.scrollIntoView();
    };
    const checkoutClickHandler = (e) => {
        if (authState.authenticated) {
            logGAEvent('view_cart_btn_click','View cart button clicked');
            navigate('/checkout');
        } else {
            storeLastLocation('Checkout');
            navigate('/login');
        }
    };
    const storeLastLocation = async (pageName = '', cb) => {
        try {
            await localStorage.setItem(LAST_PAGE_VISIT, pageName);
            if (cb) cb();
        } catch (error) {}
    };

    const updateLocalCartItems = async () => {
        try {
            const stringCartitems = await localStorage.getItem(CART_ITEMS);
            const cartItems = stringCartitems
                ? JSON.parse(stringCartitems)
                : [];

            setLocalCart(cartItems);
            const updatedOriginalMenuItems = initializeMenuItems(
                JSON.parse(JSON.stringify(categorywithItems)),
                cartItems
            );
            setLocalCategoryItems(updatedOriginalMenuItems);
            updateLocalCartTotal();
        } catch (error) {
        } finally {
        }
    };

    const handleAddonModal = (menuItem, cartItemId = null) => {
        setselectedMenuItem(menuItem);
        setselectedCartItemID(cartItemId);
    };
    const handleAddonsModal = async (menuItem, operationType) => {
        if (menuItem.cartItems?.length > 0) {
            // Here operation will be managed dynamically in the confirmation dialog based on user feedback
            setselectedMenuItem(menuItem);
            setAddonConfirmItem(menuItem);
            setAddonRepeatModal(true);
            // updateLocalCartItems();
        } else if (operationType === 'increment') {
            //    If no selected items then directly show the addon modal
            setAddonModal(true);
            await handleAddonModal(menuItem);
        }
    };
    const getAddonOperationType = (data) => {
        setAddonConfirmItem({});
        // update feature is disabled  (uncomment line 60 in restaurant-addon-confirmation.html to enable this feature)
        if (data && data.operation === 'update') {
            // If cart has been updated then updating the cart instance
            // handleAddonModal(data.cartItemId);
        } else if (data && data.operation === 'add') {
            // If cart has been updated then updating the cart instance
            setAddonModal(true);
            handleAddonModal(data.menuItem);
        } else {
            // If cart has been deleted or duplicated the updating the cart instance
            updateLocalCartItems();
        }
    };

    const updateItemQuantity = async (item, operationType) => {
        // remove item (remove last item, if item has 2 count) from cart
        const decrementItem = () => {
            // remove cart item if item count is 1
            if (item.cartItems[0].cart_item_count === 1) {
                const removedItem = item.cartItems[item.cartItems?.length - 1];
                item.cartItems = [];
                removeLocalCartItem(removedItem.cartItemId, () => {
                    updateLocalCartItems();
                    logGAEvent('remove_from_cart', item.id);
            });
            } else {
                // IDecrement cart_item_count if item doesn't have sub-addon //
                item.cartItems[0].cart_item_count += -1;
                updateCartItem(item.cartItems[0], () => {
                    logGAEvent('decrease_quantity', item.id);
                    updateLocalCartItems()
                });
            }
            // updateLocalCartItems();
        };

        if (
            item.sub_addons?.map((x) => x.enabled).indexOf(1) > -1 ||
            item.without_subaddon_addons?.length > 0
        ) {
            if (operationType !== 'increment' && item.cartItems?.length === 1) {
                // If selected item has only one category of selected cart item and contains sub-addons to remove
                return decrementItem();
            }

            // If it has sub-addons
            await handleAddonsModal(item, operationType);
        } else {
            // If item doesnt has sub-addons
            if (operationType === 'increment') {
                if (item?.cartItems?.length === 0) {
                    // Add item if item doesn't have sub-addon //
                    const cartItem = generateCartItem(item);
                    // let newItem = JSON.parse(JSON.stringify(item));
                    item.cartItems.push(cartItem);
                    addLocalCartItem(cartItem, () => {
                        logGAEvent('add_to_cart', item.id);
                        updateLocalCartItems()
                    });
                } else {
                    // Increment cart_item_count if item doesn't have sub-addon //
                    item.cartItems[0].cart_item_count += 1;
                    updateCartItem(item.cartItems[0], () => {
                        logGAEvent('increase_quantity', item.id);
                        updateLocalCartItems()
                });
                }
            } else {
                // Remove item if item doesnt have sub-addon
                decrementItem();
            }
        }
    };

    const updateLocalCartTotal = async (cartItems = null) => {
        try {
            const stringCartitems = await localStorage.getItem(CART_ITEMS);
            if (!cartItems) {
                cartItems = stringCartitems ? JSON.parse(stringCartitems) : [];
            }
            const { itemsTotal = 0 } = getItemsAndTotal(cartItems);
            setTotal(itemsTotal);
        } catch (error) {}
    };
    return (
        <div
            className='flex flex-col bg-white relative'
            style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
        >
            <div className='sticky top-0 left-0 flex justify-between items-center w-full flex-row z-10 bg-white px-4 h-12 shadow'>
                <div
                    className=' bg-white  rounded-full p-1 shadow-lg w-8 h-8 flex justify-center items-center'
                    onClick={() => {
                        logGAEvent('back_button_click', 'User clicked the back button');
                        navigate(-1);
                    }}
                    style={{ backgroundColor: themeColors.primaryColor }}
                >
                    <ArrowLeftOutlined style={{ color: '#fff' }} />
                </div>
                <div></div>
                <DisclaimerComponent
                    message={translation['disclaimer_msg']}
                    dark
                />
            </div>
            <div className='sticky top-12  flex flex-col w-full z-10'>
                <div
                    className='flex flex-row w-full justify-between items-start px-4 '
                    style={{ backgroundColor: themeColors.primaryColor }}
                >
                    {loading ? (
                        <div className='flex justify-center items-center w-full gap-2 py-2'>
                            <div className='flex'>
                                <Skeleton.Input
                                    active={true}
                                    size={'default'}
                                    style={{ width: '100%', minWidth: '7rem' }}
                                />
                            </div>
                            <div className='flex'>
                                <Skeleton.Input
                                    active={true}
                                    size={'default'}
                                    style={{ width: '100%', minWidth: '7rem' }}
                                />
                            </div>
                            <div className='flex'>
                                <Skeleton.Input
                                    active={true}
                                    size={'default'}
                                    style={{ width: '100%', minWidth: '7rem' }}
                                />
                            </div>
                        </div>
                    ) : localCategoryItems.length > 0 ? (
                        <div
                            className='relative flex flex-col w-full justify-center items-center py-2'
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                        >
                            <div className='text-sm font-medium text-center mb-1 text-white'>
                                {translation['order_menu']}
                            </div>

                            <div className='flex flex-row justify-between w-full'>
                            {serviceType==='delivery'?
                                <div className='flex flex-col justify-center items-center'>
                                    <div className='text-white text-center text-xs'>
                                        {translation['delivery_fee']}
                                    </div>
                                    <div
                                        className='text-white font-medium text-xs text-center '
                                        style={{ fontSize: 11 }}
                                    >
                                        {renderCurrency(10)}
                                    </div>
                                </div>
                            :''}
                                <div className='flex flex-col justify-center items-center'>
                                    <div
                                        className='text-white text-center '
                                        style={{ fontSize: 12 }}
                                    >
                                        {translation['delivery_time']}
                                    </div>
                                    <div
                                        className='text-white font-medium text-xs text-center '
                                        style={{ fontSize: 11 }}
                                    >
                                    {serviceType === 'delivery' ? '60 mins' : '30 mins'}
                                    </div>
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                    <div
                                        className='text-white text-center '
                                        style={{ fontSize: 12 }}
                                    >
                                        {translation['delivered_by']}
                                    </div>
                                    <div
                                        className='text-white font-medium text-xs text-center '
                                        style={{ fontSize: 11 }}
                                    >
                                        W2GO
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                {localCategoryItems.length > 0 && (
                    <div
                        className='py-2 flex flex-row justify-start px-2 overflow-x-auto bg-white shadow-sm'
                        style={{
                            scrollbarWidth: 'none',
                        }}
                    >
                        <div className='flex flex-row justify-center text-xs gap-2'>
                            {localCategoryItems.map((item, index) => (
                                <p
                                    key={item.id}
                                    onClick={() => handleCategoryClick(item.id)}
                                    style={{
                                        color:
                                            item.id === selectedIndex
                                                ? '#fff'
                                                : themeColors.primaryColor,
                                        backgroundColor:
                                            item.id === selectedIndex
                                                ? themeColors.primaryColor
                                                : '#fff',
                                    }}
                                    id={`top-scroll-${item.id}`}
                                    className='flex w-fit whitespace-nowrap shadow-sm rounded-lg px-4 py-2 border'
                                >
                                    {item.name}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className='w-full flex-1 bg-white relative pb-20'>
                {loading ? (
                    <div className='flex justify-center items-center relative'>
                        <ItemLoader />
                    </div>
                ) : error ? (
                    <div className='flex-col flex-1 gap-2 justify-center items-center '>
                        <div
                            className='text-xs text-center '
                            style={{ color: 'red' }}
                        >
                            {translation['something_went_wrong']}
                        </div>
                    </div>
                ) : localCategoryItems.length === 0 ? (
                    <div className='flex justify-center items-center'>
                        {translation['no_menu_available']}
                    </div>
                ) : (
                    localCategoryItems.map(
                        (item) =>
                            Boolean(item.enabled) && (
                                <CategoryCard
                                    key={item.id}
                                    category={item}
                                    updateItemQuantity={updateItemQuantity}
                                    localCart={localCart}
                                    isViewOnly={isViewOnly}
                                />
                            )
                    )
                )}
            </div>
            {localCategoryItems.length > 0 && (
                <div
                    className={
                        (localCategoryItems.length > 0
                            ? ' fixed bottom-24'
                            : 'fixed bottom-24') +
                        '  z-50  flex justify-center items-center w-auto left-2/4 -translate-x-2/4'
                    }
                >
                    <div
                        className='flex-row bg-gray-50 p-2 px-4 rounded-full shadow '
                        style={{ backgroundColor: themeColors.primaryColor }}
                        onClick={() => setCategoryModal(true)}
                    >
                        <div className='flex flex-row'>
                            <BarsOutlined style={{ color: '#fff' }} />
                            <div className='text-white ml-2'>
                                {translation['menu']}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={
                    (localCategoryItems.length > 0
                        ? ' fixed bottom-0'
                        : 'absolute bottom-0') +
                    ' left-0 bottom-0 right-0 z-50  bg-white shadow-lg p-4 '
                }
            >
                <Button
                    style={{
                        backgroundColor:
                            localCart.length == 0
                                ? 'gray'
                                : themeColors.primaryColor,
                    }}
                    className='flex flex-row justify-between items-center rounded-full shadow-lg bg-white  p-3 w-full h-14'
                    onClick={checkoutClickHandler}
                    disabled={localCart.length === 0}
                >
                    <div
                        className='flex w-8 h-8 rounded-full justify-center items-center'
                        style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
                    >
                        <div className='font-extrabold text-white text-md'>
                            {localCart.length}
                        </div>
                    </div>
                    <div className='text-white text-lg font-medium'>
                        {translation['view_Cart']}
                    </div>
                    <div className='text-white text-sm font-extrabold'>
                        {renderCurrency(total)}
                    </div>
                </Button>
            </div>
            <Drawer
                title='Explore Categories'
                placement={'bottom'}
                closable={false}
                onClose={() => setCategoryModal(false)}
                open={categoryModal}
                className='category-modal'
                extra={
                    <Button
                        className='flex justify-center items-center'
                        onClick={() => setCategoryModal(false)}
                    >
                        <CloseOutlined />
                    </Button>
                }
            >
                {localCategoryItems.map((category, index) => (
                    <div
                        key={category.id}
                        className={
                            'flex flex-row justify-between items-center bg-gray-100 p-3 rounded-lg mb-2 ' +
                            (index === localCategoryItems.length ? 'mb-6' : '')
                        }
                        onClick={() => {
                            handleCategoryClick(category.id);
                            setCategoryModal(false);
                        }}
                    >
                        <div className=' text-black text-xs'>
                            {category.name}
                        </div>
                    </div>
                ))}
            </Drawer>

            {addonModal && (
                <Drawer
                    title={translation['choose_customization']}
                    placement={'bottom'}
                    closable={false}
                    onClose={() => setAddonModal(false)}
                    open={addonModal}
                    rootClassName='addon-modal'
                >
                    <AddonComponent
                        selectedCartItemID={selectedCartItemID}
                        selectedMenuItem={selectedMenuItem}
                        updateLocalCartItems={updateLocalCartItems}
                        close={() => closeAddonSheet()}
                    />
                </Drawer>
            )}

            {addonRepeatModal && (
                <Drawer
                    title={translation['repeat_customization']}
                    placement={'bottom'}
                    closable={false}
                    onClose={() => setAddonRepeatModal(false)}
                    open={addonRepeatModal}
                    size='default'
                    rootClassName='addon-repeat-modal'
                >
                    <AddonRepeatConfirm
                        selectedItemList={addonConfirmItem}
                        originalItem={addonConfirmItem}
                        close={() => closeAddonRepeatSheet()}
                        updateOperationType={getAddonOperationType}
                        updateLocalCartItems={updateLocalCartItems}
                        dismissAddon={() => setAddonModal(false)}
                        dismissRepeatAddon={() => setAddonRepeatModal(false)}
                    />
                </Drawer>
            )}
        </div>
    );
};
const ItemLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={window.innerWidth - 10}
            height={500}
            viewBox='0 0 350 500'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
            {...props}
        >
            <rect x='260' y='5' rx='9' ry='9' width='79' height='82' />
            <rect x='19' y='5' rx='4' ry='4' width='220' height='15' />
            <rect x='19' y='24' rx='4' ry='4' width='220' height='16' />
            <rect x='18' y='56' rx='16' ry='16' width='96' height='30' />
            <rect x='260' y='109' rx='9' ry='9' width='79' height='82' />
            <rect x='19' y='109' rx='4' ry='4' width='220' height='15' />
            <rect x='19' y='128' rx='4' ry='4' width='220' height='16' />
            <rect x='18' y='160' rx='16' ry='16' width='96' height='30' />
            <rect x='260' y='213' rx='9' ry='9' width='79' height='82' />
            <rect x='20' y='213' rx='4' ry='4' width='220' height='15' />
            <rect x='20' y='232' rx='4' ry='4' width='220' height='16' />
            <rect x='19' y='264' rx='16' ry='16' width='96' height='30' />
            <rect x='260' y='314' rx='9' ry='9' width='79' height='82' />
            <rect x='20' y='314' rx='4' ry='4' width='220' height='15' />
            <rect x='20' y='333' rx='4' ry='4' width='220' height='16' />
            <rect x='19' y='365' rx='16' ry='16' width='96' height='30' />
            <rect x='260' y='416' rx='9' ry='9' width='79' height='82' />
            <rect x='20' y='416' rx='4' ry='4' width='220' height='15' />
            <rect x='20' y='435' rx='4' ry='4' width='220' height='16' />
            <rect x='19' y='467' rx='16' ry='16' width='96' height='30' />
        </ContentLoader>
    );
};

export default OrderMenuScreen;
