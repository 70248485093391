import React, { useState } from 'react';
import { themeColors } from '../theme';
import CookingIntructions from '../components/CookingIntructions';
import {
    getFormattedCartItems,
    getItemsAndTotal,
    renderCurrency,
} from '../services/commonHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
    hotelSelector,
    updateItemsInstructionMessage,
} from '../store/slices/hotel/hotelSlice';
import Counter from '../components/Counter';
import { removeLocalCartItem, updateAllCartItem } from '../services/cartHelper';
import W2GO from '../assets/W2GO.png';
import { placeFoodOrder } from '../services/apiService';
import PaymentComponent from '../components/PaymentComponent';
import HTMLContent from '../components/HTMLContent';
import {
    CART_ITEMS,
    LAST_PAGE_VISIT,
    OUTLET_ID,
    SERVICETYPE,
} from '../services/Typecode';
import { useLanguage } from '../context/LanguageContext';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CloseOutlined,
    EnvironmentOutlined,
    CloseCircleFilled,
} from '@ant-design/icons';
import { Button, Drawer, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import BonvoyMember from '../components/BonvoyMember';
import DisclaimerComponent from '../components/DisclaimerComponent';
import TextArea from 'antd/es/input/TextArea';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const CheckoutScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = React.useState(0);
    const [finalAmount, setFinalAmount] = React.useState(0);
    const { loading, selectedAddress, itemsInstructionMessages } =
        useSelector(hotelSelector);

    const logGAEvent = useAnalyticsEventTracker('CheckoutScreen');

    const [paymentModal, setPaymentModal] = React.useState(false);
    const [paymentMode, setPaymentMode] = useState('CASH');
    const [cookingInstruction, setCookingInstruction] = React.useState('');
    const { translation } = useLanguage();
    const [paymentLoader, setPaymentLoader] = React.useState(false);
    const [membershipNo, setMembershipNo] = useState('');
    const serviceType = localStorage.getItem(SERVICETYPE);
    const pageName = localStorage.getItem(LAST_PAGE_VISIT);
    React.useEffect(() => {
        updateLocalCartAndTotal();
    }, [selectedAddress, itemsInstructionMessages]);

    const updateLocalCartAndTotal = async (cartItems = null) => {
        try {
            const stringCartitems = await localStorage.getItem(CART_ITEMS);
            if (!cartItems) {
                cartItems = stringCartitems ? JSON.parse(stringCartitems) : [];
            }
            const { fetchedcartItemList, itemsTotal } = getItemsAndTotal(
                cartItems,
                itemsInstructionMessages
            );
            setCartItems(fetchedcartItemList);

            let totalWithCharges = itemsTotal;
            if (serviceType !== 'takeaway') {
                totalWithCharges += selectedAddress.delivery_charge || 0;
            }
            setFinalAmount(totalWithCharges);
            setTotal(itemsTotal);
        } catch (error) {}
    };

    const updateItemCount = (item, operationType) => {
        const updateCount = async (itemId, count) => {
            try {
                let localCartItems = await localStorage.getItem(CART_ITEMS);
                localCartItems = localCartItems
                    ? JSON.parse(localCartItems)
                    : [];
                const updatedCartItems = localCartItems.map((cartItem) => {
                    if (itemId === cartItem.cartItemId) {
                        cartItem.cart_item_count += count;
                    }
                    return cartItem;
                });
                updateAllCartItem(updatedCartItems);
                updateLocalCartAndTotal(updatedCartItems);
            } catch (error) {}
        };
        if (item.itemCount === 1 && operationType === 'decrement') {
            // Removing cart item if count is 1  //
            removeLocalCartItem(item.cartItemId, () =>
                updateLocalCartAndTotal()
            );
        } else {
            // Decrementing item count is count > 1 //
            if (operationType === 'increment') {
                logGAEvent('increment_btn_click', item.id);
                updateCount(item.cartItemId, 1);
            } else if (operationType === 'decrement') {
                logGAEvent('decrement_btn_click', item.id);
                updateCount(item.cartItemId, -1);
            }
        }
    };
    const itemInstruction = (itemId, instructionMsg) => {
        dispatch(updateItemsInstructionMessage({ [itemId]: instructionMsg }));
    };

    const payNowClickHandler = () => {
        // let userData =  localStorage.getItem(USER_PROPS);
        // userData = userData ? JSON.parse(userData) : {};
        logGAEvent('pay_now_btn_click', 'Pay Now Button Clicked');
        const hotelID = localStorage.getItem('__hotelID');
        const outletID = localStorage.getItem(OUTLET_ID);

        const data = {
            enc_hotel_id: hotelID,
            outlet_id: outletID,
            address_id:
                serviceType === 'delivery' ? selectedAddress.id : undefined,
            service_type: serviceType,
            payment_status: 'offline',
            payment_type: paymentMode,
            mobile_number: '',
            coupon_code: '',
            membership_no: '',
        };
        if (!data.address_id && serviceType === 'delivery') {
            api.open({
                description: translation['please_select_an_address'],
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });

            return;
        } else if (total && total < 350) {
            api.open({
                description: translation['minimum_item_total'],
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });

            return;
        } else {
            setPaymentModal(true);
            return;
        }
    };
    const placeOrder = async () => {
        try {
            logGAEvent('place_order_btn_click', 'Place Order Button Clicked');
            setPaymentLoader(true);
            const hotelID = localStorage.getItem('__hotelID');
            const outletID = localStorage.getItem(OUTLET_ID);

            const data = {
                enc_hotel_id: hotelID,
                outlet_id: outletID,
                address_id:
                    serviceType === 'delivery' ? selectedAddress.id : undefined,
                slots: '12/22/2023 undefined',
                service_type: serviceType,
                description: cookingInstruction,
                items: getFormattedCartItems(cartItems),
                payment_status: 'offline',
                payment_type: paymentMode,
                mobile_number: '',
                coupon_code: '',
                membership_no: membershipNo,
                order_version: 'v2',
            };

            const logMessage = `${serviceType} by ${paymentMode.toLowerCase()}`;
            logGAEvent(
                logMessage,
                `Order was a ${serviceType}, paid by ${paymentMode}`
            );

            const response = await placeFoodOrder(data);
            if (response.status === 201) {
                logGAEvent(
                    'order_placed_successfully',
                    'User placed the order successfully'
                );
                const result = response.data.result || {};
                // navigate('/thankyou',{orderID: result.id }));
                navigate(`/thankyou?orderID=${result.id}`, { replace: true });
            }
        } catch (error) {
            logGAEvent('order_place_failure', error.message);
            console.log(error);
        } finally {
            setPaymentLoader(false);
        }
    };
    return (
        <div
            className='flex flex-1 flex-col relative'
            style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
        >
            {contextHolder}
            <div className='sticky top-0 w-full flex flex-row justify-between items-center px-4 py-2 bg-white z-10 shadow'>
                <div
                    className='flex bg-gray-50 rounded-full p-2 shadow-lg'
                    onClick={() => {
                        logGAEvent(
                            'back_button_click',
                            'User clicked the back button'
                        );
                        navigate(-1);
                    }}
                    style={{ backgroundColor: themeColors.bgColor(1) }}
                >
                    <ArrowLeftOutlined style={{ color: '#fff' }} />
                </div>
                <div>
                    <div className=' text-lg font-medium ml-8'>
                        {translation['checkout']}
                    </div>
                </div>
                <div className='flex flex-row gap-1 justify-center items-center'>
                    <div
                        className='flex h-7 rounded-full justify-center items-center px-3 py-0.5 text-black text-xs'
                        style={{ backgroundColor: themeColors.bgColor(0.5) }}
                    >
                        {translation['items']}: {cartItems.length}
                    </div>
                    <DisclaimerComponent
                        message={translation['disclaimer_msg']}
                        dark
                    />
                </div>
            </div>

            {cartItems.length > 0 && (
                <div
                    className='relative flex flex-row w-full justify-between items-start p-4'
                    style={{ backgroundColor: themeColors.primaryColor }}
                >
                    <div className='flex flex-row gap-x-2 items-start'>
                        <EnvironmentOutlined
                            style={{ color: '#fff', marginTop: '2px' }}
                        />
                        <div className=' flex flex-col'>
                            <div className='text-white text-sm text-left'>
                                {serviceType === 'delivery'
                                    ? translation['delivery_address']
                                    : translation['pickup_address']}
                            </div>
                            <div
                                className='text-gray-300 text-sm text-left'
                                style={{ fontSize: 12 }}
                            >
                                {serviceType === 'delivery'
                                    ? selectedAddress.address
                                    : 'W Doha, Building 262, Street 831, Doha, Qatar'}
                            </div>
                        </div>
                    </div>

                    {serviceType === 'delivery' && (
                        <div
                            className=' rounded-full p-0'
                            onClick={() => navigate('/changeaddress')}
                        >
                            <div className='text-sm text-blue-300 rounded-full'>
                                {translation['change']}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className='flex flex-1 flex-col justify-start items-start pb-24 '>
                <div className='text-black text-lg font-semibold my-1.5 px-4'>
                    {translation['cart_items']}
                </div>

                {cartItems.length > 0 ? (
                    <div className='w-full flex-1 relative px-4 pb-0'>
                        {cartItems.map((item) => (
                            <CheckoutItemCard
                                key={item.cartItemId}
                                item={item}
                                index={0}
                                counter={true}
                                updateItemCount={updateItemCount}
                                instruction={item.instruction}
                                itemInstruction={itemInstruction}
                            />
                        ))}
                        <CookingIntructions
                            setCookingInstruction={setCookingInstruction}
                            cookingInstruction={cookingInstruction}
                        />
                        <BonvoyMember
                            membershipNo={membershipNo}
                            setMembershipNo={setMembershipNo}
                        />
                        <div className='flex flex-col bg-white p-3  rounded-xl mb-4 flex-1 shadow '>
                            <div className='flex flex-1 flex-row justify-between mb-2 py-0.25 '>
                                <div className='text-sm text-black'>
                                    {translation['items_total']}
                                </div>
                                <div className='text-sm text-black'>
                                    {renderCurrency(total)}
                                </div>
                            </div>
                            {serviceType === 'delivery' && (
                                <div className='flex flex-1 flex-row justify-between mb-2 py-0.25 '>
                                    <div className='text-xs text-gray-500'>
                                        {translation['delivery_charges']}
                                    </div>
                                    <div className='text-xs text-gray-500'>
                                        {renderCurrency(
                                            selectedAddress.delivery_charge || 0
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='flex flex-1 flex-row justify-between py-1 border-0 border-t border-gray-200 pt-2'>
                                <div className='text-md text-black font-semibold'>
                                    {translation['grand_Total']}
                                </div>
                                <div className='text-md text-black font-semibold'>
                                    {renderCurrency(finalAmount)}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col w-full h-40 flex-1 relative px-4 pb-0 justify-center items-center'>
                        <div className='mb-2'>
                            {translation['no_items_found']}
                        </div>
                        <Button
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                            className='flex-row text-center shadow-lg h-10 justify-center items-center px-4 text-xs text-white rounded-full'
                            onClick={() =>
                                pageName === 'orderhistory'
                                    ? navigate('/')
                                    : navigate(-1)
                            }
                        >
                            {translation['back_to_menu']}
                        </Button>
                    </div>
                )}
            </div>
            {cartItems.length > 0 && (
                <div className='fixed bottom-0 w-full z-40 bg-slate-50 p-4'>
                    <div className='flex flex-row justify-between items-center rounded-full shadow-lg bg-white  p-1'>
                        <div className='flex justify-center items-center flex-1'>
                            <div className='font-semibold text-black text-md'>
                                {renderCurrency(finalAmount)}
                            </div>
                        </div>

                        <Button
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                            className='flex flex-row text-center rounded-full shadow-lg flex-1 h-12 justify-center items-center gap-x-2'
                            onClick={payNowClickHandler}
                        >
                            <div className='text-white text-md text-center'>
                                {translation['pay_now']}
                            </div>
                            <ArrowRightOutlined style={{ color: '#fff' }} />
                        </Button>
                    </div>
                </div>
            )}
            <Drawer
                title={translation['select_payment_method']}
                placement={'bottom'}
                closable={false}
                onClose={() => setPaymentModal(false)}
                open={paymentModal}
                extra={
                    <Button
                        className='flex justify-center items-center'
                        onClick={() => setPaymentModal(false)}
                    >
                        <CloseOutlined />
                    </Button>
                }
            >
                <PaymentComponent
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    placeOrder={placeOrder}
                    translation={translation}
                    paymentLoader={paymentLoader}
                />
            </Drawer>
        </div>
    );
};
const CheckoutItemCard = (props) => {
    const { item, updateItemCount, itemInstruction, instruction } = props;
    const { translation } = useLanguage();

    return (
        <div
            className={
                'flex flex-col bg-white rounded-3xl mb-4 flex-1 shadow border border-gray-200 p-2 pb-3 gap-2 w-full'
            }
        >
            <div className='flex flex-1 flex-row-reverse  gap-2 w-full'>
                <div className='relative flex flex-col justify-start w-24 items-center'>
                    {item.thumbnail ? (
                        <img
                            className={'rounded-xl w-20 h-20 object-cover'}
                            alt='item'
                            src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${item.thumbnail}`}
                        />
                    ) : (
                        <img
                            className={'rounded-2xl w-24 h-24 object-cover'}
                            src={W2GO}
                            alt='item'
                        />
                    )}
                    <Counter
                        decrease={() => updateItemCount(item, 'decrement')}
                        increase={() => updateItemCount(item, 'increment')}
                        count={item.itemCount}
                    />
                </div>
                <div
                    className={
                        'w-full flex flex-col justify-start flex-1 ml-1 pt-0.5'
                    }
                >
                    <div className='text-sm font-normal'>{item.itemName}</div>
                    {item.description && (
                        <div className='text-xs text-gray-700'>
                            <HTMLContent html={item.description} />
                        </div>
                    )}
                    {item.addons?.length > 0 &&
                        item?.addons?.map((add, index) => (
                            <div className='pl-2' key={`${add.title}-${index}`}>
                                <div className='text-xs text-gray-700'>
                                    - {add.title}: {add.name}
                                    {add.addonPrice > 0 &&
                                        `- ${renderCurrency(add.addonPrice)}`}
                                </div>
                            </div>
                        ))}

                    <div className='flex-row w-full justify-between items-center mt-auto'>
                        <div className='text-xs font-bold'>
                            {renderCurrency(item.itemPrice)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex'>
                <TextArea
                    rows={2}
                    className='h-12 border rounded-lg border-gray-300 p-3'
                    style={{ textAlignVertical: 'top' }}
                    placeholder={translation['enter_cooking_instructions']}
                    onChange={(e) =>
                        itemInstruction(item.cartItemId, e.target.value)
                    }
                    value={instruction}
                />
            </div>
        </div>
    );
};

export default CheckoutScreen;
