import { Result } from 'antd';
import React, { useEffect } from 'react';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const PageNotFound = () => {
    const logGAEvent = useAnalyticsEventTracker('page_not_found');

    useEffect(() => {
        logGAEvent('page_not_found', '');
    }, []);

    return (
        <div className='flex flex-col justify-center items-center flex-1 min-h-screen'>
            <Result
                status='500'
                title='Page Not Found'
                subTitle='Seems Something went wrong! Please scran the QR again or open the link again'
            />
        </div>
    );
};

export default PageNotFound;
