import { createContext, useContext, useEffect, useState } from 'react';
import { getHotelDetails } from '../services/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const HotelContext = createContext({});
export const useHotel = () => useContext(HotelContext);
function HotelContextProvider(props) {
    const [hotelDetails, setHotelDetails] = useState({});

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname.includes('welcome')) {
            let splitedLocation = location.pathname.split('/');
            if (splitedLocation.length === 3) {
                if (
                    splitedLocation[1] === 'welcome' &&
                    splitedLocation[2].length > 10
                ) {
                    localStorage.setItem('__hotelID', splitedLocation[2]);
                    navigate('/', { replace: true });
                }
            }
            ReactGA.set({ 'hotel_id': splitedLocation[2] });
        }
    }, [location.pathname]);

    useEffect(() => {
        let hotelID = localStorage.getItem('__hotelID');
        if(!hotelID){
            navigate('/page-not-found',{replace: true});
        }
    }, []);
    
    useEffect(() => {
        fetchHotelDetails();
    }, []);
    const fetchHotelDetails = async () => {
        try {
            const response = await getHotelDetails();
            if (response.status === 200) {
                const properties = response.data.data || {};
                setHotelDetails(properties);
            }
        } catch (error) {}
    };

    const value = {
        hotelDetails,
    };
    return (
        <HotelContext.Provider value={value}>
            {props.children}
        </HotelContext.Provider>
    );
}

export default HotelContextProvider;
