import React, { useState } from 'react';

import { themeColors } from '../theme';

import { getOrderHistory } from '../services/apiService';
import { renderCurrency } from '../services/commonHelper';
import { useLanguage } from '../context/LanguageContext';
import {
    ArrowLeftOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Skeleton } from 'antd';
import RepeatLastOrder from '../components/RepeatLastOrder';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
const totalforUserHistory = (order_menu_items = []) => {
    let totalTaxes = 0;
    let itemTotal = 0;
    order_menu_items?.forEach((item) => {
        let itemPrice = 0;
        let addonPrice = 0;

        if (item.price > 0) {
            itemPrice += item.price * item.quantity;
        }
        // adding selected addons list (also adding price and taxes)
        const updateTempSubItem = (addOns) => {
            addOns?.forEach((addOn) => {
                if (addOn.price > 0) {
                    const totalAddonPrice = addOn.price * item.quantity;
                    addonPrice += totalAddonPrice;
                }
            });
        };

        updateTempSubItem(item.order_addons);
        itemPrice = itemPrice + addonPrice; // updating item price
        itemTotal += itemPrice; // updating total price
    });
    let itemsTotal = itemTotal + totalTaxes;
    return itemsTotal;
};
const OrderHistoryScreen = () => {

    const logGAEvent = useAnalyticsEventTracker('OrderHistoryScreen');
    const navigate = useNavigate();
    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [repeatOrderPopupOpen, setRepeatOrderPopupOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { translation } = useLanguage();

    React.useEffect(() => {
        fetchOrderHistory();
    }, []);

    const fetchOrderHistory = async () => {
        try {
            setOrderHistoryList([]);
            setLoading(true);
            const response = await getOrderHistory();
            if (response?.status === 200) {
                logGAEvent('fetch_order_history', 'Order History Fetched');
                let dataList = response.data.data || [];
                setOrderHistoryList(dataList);
            }
        } catch (error) {
            logGAEvent('error_fetching_history', error.message);
            setError('Something went wrong! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const openRepeatOrderPopup = (e, data) => {
        logGAEvent('repeat_order_btn_clicked','User clicked Order Again button');
        e.stopPropagation();
        setRepeatOrderPopupOpen(true);
        setOrderDetails(data);
    };

    const renderItemsTotal = (order) => {
        let value = totalforUserHistory(order.order_menu_items || []);
        if (order.order_detail?.service_type === 'delivery') {
            value += order.order_detail?.amount_details?.delivery_charge;
        }
        if (order.order_detail?.amount_details?.tax > 0) {
            value += order.order_detail?.amount_details?.tax;
        }
        return renderCurrency(value);
    };
    return (
        <>
            <div
                className='flex flex-1 flex-col '
                style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
            >
                <div className='flex sticky top-0 left-4 w-full flex-row justify-between items-center bg-white z-10 px-4 py-2 shadow'>
                    <div
                        className='flex bg-gray-50 rounded-full p-2 shadow-lg'
                        style={{ backgroundColor: themeColors.primaryColor }}
                        onClick={() => {
                            logGAEvent('back_button_click', 'User clicked the back button');
                            navigate('/', { replace: true })
                        }}
                    >
                        <ArrowLeftOutlined style={{ color: '#fff' }} />
                    </div>
                    <div>
                        <div className='text-black text-lg font-medium'>
                            {translation['order_history']}
                        </div>
                    </div>
                    <div
                        className=' rounded-full justify-center items-center '
                        onClick={fetchOrderHistory}
                    >
                        <ReloadOutlined />
                    </div>
                </div>
                <div className='flex flex-1 flex-col justify-start items-start pb-0 '>
                    <div className='w-full flex-1 relative pb-0 pt-2 px-4'>
                        {loading ? (
                            <div className='flex flex-col flex-1 gap-2 justify-center items-center py-5 flex-col'>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </div>
                        ) : error ? (
                            <div className='flex flex-col flex-1 gap-2 justify-center items-center '>
                                <CheckCircleOutlined />
                                <div
                                    className='text-xs text-center '
                                    style={{ color: 'red' }}
                                >
                                    {translation['something_went_wrong']}
                                </div>
                            </div>
                        ) : orderHistoryList.length === 0 ? (
                            <div className='flex justify-center items-center py-5'>
                                <div>{translation['no_orders_available']}</div>
                            </div>
                        ) : (
                            orderHistoryList.map((order) => (
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/trackorder?orderID=${order.id}`
                                        )
                                    }
                                    key={order.id}
                                >
                                    <div
                                        className={
                                            'flex bg-white rounded-2xl mb-4 shadow border border-gray-200 flex-row p-4'
                                        }
                                    >
                                        <div
                                            className={
                                                'flex flex-row  flex-1 justify-between items-start'
                                            }
                                        >
                                            <div className='flex flex-col gap-y-1 w-9/12 justify-start items-start'>
                                                <div className='text-sm font-semibold'>
                                                    {
                                                        translation[
                                                            'order_number'
                                                        ]
                                                    }
                                                    : #{order.id}
                                                </div>
                                                <div className='text-xs text-gray-700'>
                                                    <div className='font-semibold'>
                                                        {
                                                            translation[
                                                                'order_date'
                                                            ]
                                                        }
                                                        :{' '}
                                                        {new Date(
                                                            order.created_at
                                                        ).toLocaleDateString()}{' '}
                                                        |{' '}
                                                        {new Date(
                                                            order.created_at
                                                        ).toLocaleTimeString()}
                                                    </div>
                                                </div>
                                                {order.order_detail
                                                    .service_type ===
                                                    'delivery' && (
                                                    <div className=' flex flex-row text-xs text-gray-700 w-full'>
                                                        {/* <div  >
                                                        
                                                    </div> */}
                                                        <div className='flex-1 flex-wrap	'>
                                                            <div className='font-semibold'>
                                                                {
                                                                    translation[
                                                                        'delivery_to'
                                                                    ]
                                                                }
                                                                :
                                                            </div>
                                                            {order
                                                                .order_address?.[0]
                                                                ?.address || ''}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='flex flex-col justify-center items-center space-y-3'>
                                                <div className='text-xs font-bold'>
                                                    {renderItemsTotal(order)}
                                                </div>
                                                {order.order_detail
                                                    .cleared_at ? (
                                                    <div
                                                        className=' flex-row rounded-full shadow w-6 h-6 flex justify-center items-center'
                                                        style={{
                                                            backgroundColor:
                                                                'green',
                                                        }}
                                                    >
                                                        <CheckCircleOutlined
                                                            style={{
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    </div>
                                                ) : order.order_detail
                                                      .canceled_on ? (
                                                    <div
                                                        className=' flex-row rounded-full shadow w-6 h-6 flex justify-center items-center'
                                                        style={{
                                                            backgroundColor:
                                                                'red',
                                                        }}
                                                    >
                                                        <CheckCircleOutlined
                                                            style={{
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className=' flex-row rounded-full shadow w-6 h-6 flex justify-center items-center'
                                                        style={{
                                                            backgroundColor:
                                                                'gray',
                                                        }}
                                                    >
                                                        <InfoCircleOutlined
                                                            style={{
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                <Button
                                                    className='flex rounded justify-center items-center px-3 py-0.5 text-white text-xs'
                                                    style={{
                                                        backgroundColor:
                                                            themeColors.primaryColor,
                                                    }}
                                                    onClick={(e) =>
                                                        openRepeatOrderPopup(
                                                            e,
                                                            order
                                                        )
                                                    }
                                                >
                                                    {translation['order_again']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Drawer
                title={translation['order_again']}
                placement={'bottom'}
                closable={false}
                onClose={() => setRepeatOrderPopupOpen(false)}
                open={repeatOrderPopupOpen}
                size='default'
                rootClassName='repeat-order'
                height={'550px'}
                extra={
                    <Button
                        className='flex justify-center items-center'
                        onClick={() => setRepeatOrderPopupOpen(false)}
                    >
                        <CloseOutlined />
                    </Button>
                }
            >
                <RepeatLastOrder
                    setRepeatOrderPopupOpen={setRepeatOrderPopupOpen}
                    orderID={orderDetails.id}
                    serviceType={orderDetails.order_detail?.service_type}
                />
            </Drawer>
        </>
    );
};

export default OrderHistoryScreen;
