import { Alert, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { themeColors } from '../theme';
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { addNewAddresss, getUserPostalAddress } from '../services/apiService';
import { fetchAddressList } from '../store/slices/hotel/hotelSlice';
import { useDispatch } from 'react-redux';

import { USER_LOCATION } from '../services/Typecode';
import {
    ArrowLeftOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
} from '@ant-design/icons';
import { Button, Input, notification } from 'antd';
import Autocomplete from 'react-google-autocomplete';
import { useLanguage } from '../context/LanguageContext';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const AddNewAddressScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const { translation } = useLanguage();
    const [userLocation, setUserLocation] = useState('');
    const logGAEvent = useAnalyticsEventTracker('add_new_address');

    const locaionInputRef = React.useRef(null);
    const [addressData, setAddressData] = React.useState({
        title: 'Home',
        otherAddress: '',
        completeAddress: '',
        buildingNo: '',
        streetNo: '',
        zoneNo: '',
        areaName: '',
        userFetchedAddress: '',
        contact_no: '',
    });
    const [locationData, setLocationData] = React.useState({});

    React.useEffect(() => {
        getLocationFromLatLong();
        // clearStorage();
    }, []);

    const getLocationFromLatLong = async () => {
        try {
            const stringDatLatLong = await localStorage.getItem(USER_LOCATION);
            const latLong = JSON.parse(stringDatLatLong);
            const response = await getUserPostalAddress(
                `${latLong.lat},${latLong.long}`
            );
            const data = await response.json();
            if (data?.status === 'OK') {
                let mostValues = data?.results?.sort(
                    (a, b) =>
                        b.address_components.length -
                        a.address_components.length
                );
                const { lat = '', lng = '' } =
                    mostValues?.[0]?.geometry?.location || {};
                onLocationSelection(
                    mostValues?.[0]?.address_components,
                    lat,
                    lng
                );
                let addressValue = mostValues?.[0]?.formatted_address || '';
                inputChangeHandler('userFetchedAddress', addressValue);
                setUserLocation(addressValue);
            }
        } catch (error) {}
    };
    const inputChangeHandler = (name, value) => {
        setAddressData({ ...addressData, [name]: value });
    };
    const onLocationSelection = (details = [], lat = '', lng = '') => {
        let data = {
            city: '',
            state: '',
            country: '',
            zip: null,
            lat: lat || '',
            long: lng || '',
        };
        for (let i = 0; i < details.length; i++) {
            let c = details[i];
            if (
                c.types?.includes('"country"') ||
                c.types?.includes('political')
            ) {
                data['country'] = c.long_name;
            }
            if (c.types?.includes('administrative_area_level_1')) {
                data['state'] = c.long_name?.substring(0, 40);
            }
            if (c.types?.includes('locality')) {
                data['city'] = c.long_name;
            }
            if (c.types?.includes('postal_code')) {
                data['zip'] = c.long_name;
            }
        }
        setLocationData(data);
    };
    const saveAddress = async () => {
        logGAEvent('save_address_btn_click', '');
        if (!addressData.title || !addressData.title.trim()) {
            api.open({
                description: translation['title_required'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        } else if (
            !addressData.userFetchedAddress ||
            !addressData.userFetchedAddress.trim()
        ) {
            api.open({
                description:
                    translation['confirm_location_required_for_delivery'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        } else if (
            !addressData.completeAddress ||
            !addressData.completeAddress.trim()
        ) {
            api.open({
                description: translation['address_required'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        } else if (!addressData.buildingNo || !addressData.buildingNo.trim()) {
            api.open({
                description: translation['building_No_required'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        } else if (!addressData.streetNo || !addressData.streetNo.trim()) {
            api.open({
                description: translation['street_No_required'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        } else if (!addressData.zoneNo || !addressData.zoneNo.trim()) {
            api.open({
                description: translation['zone_No_required'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            return;
        }
        try {
            const {
                title,
                otherAddress,
                completeAddress,
                buildingNo,
                streetNo,
                zoneNo,
                areaName,
                userFetchedAddress,
                contact_no,
            } = addressData;
            let newTitle = title;
            if (title === 'Other') {
                if (otherAddress.trim() !== '') {
                    newTitle = otherAddress;
                } else {
                    newTitle = `Other${buildingNo}`;
                }
            }
            const payload = {
                address: `${completeAddress || ''} | ${buildingNo || ''} | ${
                    streetNo || ''
                } | ${zoneNo || ''} | ${areaName || ''} | ${
                    userFetchedAddress || ''
                }`,
                title: newTitle,
                contact_no: contact_no,
                ...locationData,
            };
            const response = await addNewAddresss(payload);
            if (response.status === 201) {
                logGAEvent('address_added_successfully', '');

                api.open({
                    description: translation['address_added_successfully'],
                });
                dispatch(fetchAddressList());
                navigate(-1);
            }
        } catch (error) {
            const { response = {} } = error;
            if (response.status === 400) {
                logGAEvent('address_added_failed', '');

                const errorMessage =
                    response?.data?.message ||
                    translation['something_went_wrong'];
                api.open({
                    description: errorMessage,
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });
            }
        }
    };

    return (
        <div
            className='flex flex-1 flex-col '
            style={{ backgroundColor: '#f8f7f4' }}
        >
            {contextHolder}
            <div className='sticky top-0 w-full flex flex-row justify-between items-center px-4 py-2 z-10 bg-white shadow'>
                <div
                    className='flex bg-gray-50 rounded-full p-2 shadow-lg'
                    style={{ backgroundColor: themeColors.primaryColor }}
                    onClick={() => {
                        logGAEvent('add_address_back_button_click', '');
                        navigate(-1);
                    }}
                >
                    <ArrowLeftOutlined style={{ color: '#fff' }} />
                </div>
                <div>
                    <div className='text-black text-lg font-medium ml-8'>
                        {translation['add_new_address']}
                    </div>
                </div>
                <Button
                    className='flex h-7 rounded-full justify-center items-center px-3 py-0.5'
                    style={{ backgroundColor: themeColors.primaryColor }}
                    onClick={() => {
                        logGAEvent(
                            'add_address_close_button_click',
                            ''
                        );
                        navigate(-1);
                    }}
                >
                    <div className=' text-white text-xs'>
                        {translation['close']}
                    </div>
                </Button>
            </div>
            <div className='flex flex-1 flex-col justify-start items-start pb-8 '>
                {/* <div className='px-4'>
                    <div  className='text-black text-lg font-semibold'>
                        Add New Address
                    </div>
                </div> */}

                <div className=' flex flex-row w-full items-center gap-x-2 mb-3 h-8 pt-2 px-4'>
                    <div
                        className='rounded-full bg-black p-0.5 flex px-3'
                        style={{
                            backgroundColor:
                                addressData.title === 'Home'
                                    ? themeColors.bgColor(0.5)
                                    : 'transparent',
                            borderWidth: 1,
                            borderColor: themeColors.bgColor(0.5),
                        }}
                        onClick={() => inputChangeHandler('title', 'Home')}
                    >
                        <div className='text-black text-sm text-left '>
                            {translation['home']}
                        </div>
                    </div>
                    <div
                        className='rounded-full bg-transparent p-0.5 flex px-3'
                        style={{
                            backgroundColor:
                                addressData.title === 'Office'
                                    ? themeColors.bgColor(0.5)
                                    : 'transparent',
                            borderWidth: 1,
                            borderColor: themeColors.bgColor(0.5),
                        }}
                        onClick={() => inputChangeHandler('title', 'Office')}
                    >
                        <div className='text-black text-sm text-left '>
                            {translation['office']}
                        </div>
                    </div>
                    <div
                        className='rounded-full bg-transparent p-0.5 flex px-3'
                        style={{
                            backgroundColor:
                                addressData.title === 'Other'
                                    ? themeColors.bgColor(0.5)
                                    : 'transparent',
                            borderWidth: 1,
                            borderColor: themeColors.bgColor(0.5),
                        }}
                        onClick={() => inputChangeHandler('title', 'Other')}
                    >
                        <div className='text-black text-sm text-left '>
                            {translation['other']}
                        </div>
                    </div>
                    {addressData.title === 'Other' && (
                        <div className='w-32 flex'>
                            <Input
                                className='h-8 border rounded-lg border-gray-300 py-1.5 px-2 bg-white flex'
                                style={{ textAlignVertical: 'top' }}
                                placeholder={`${translation['address_title']}*`}
                                onChange={(e) =>
                                    inputChangeHandler(
                                        'otherAddress',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                    )}
                </div>

                <div className='w-full flex-1 relative pb-0 px-4'>
                    <div className='mb-2 '>
                        <div className='flex'>
                            {translation['confirm_location']}
                            <div className='text-red-800  text-lg'> *</div>
                        </div>
                        {/* <Autocomplete
                            ref={locaionInputRef}
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white w-full'
                            apiKey={'AIzaSyByaDHym0A1ItGyWo-aNdPROaLd_NrekRg'}
                            // inputAutocompleteValue={userLocation}
                            // defaultValue={userLocation}
                            onPlaceSelected={(place) => {
                                if (place?.formatted_address) {
                                    inputChangeHandler(
                                        'userFetchedAddress',
                                        place?.formatted_address
                                    );
                                }
                                onLocationSelection(place);
                            }}
                            placeholder='Enter your location *'
                        /> */}
                        <Autocomplete
                            ref={locaionInputRef}
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white w-full'
                            apiKey={'AIzaSyByaDHym0A1ItGyWo-aNdPROaLd_NrekRg'}
                            inputAutocompleteValue={userLocation}
                            defaultValue={userLocation}
                            onPlaceSelected={(place) => {
                                if (place?.formatted_address) {
                                    inputChangeHandler(
                                        'userFetchedAddress',
                                        place?.formatted_address
                                    );
                                    setUserLocation(place?.formatted_address);
                                }
                                const lat =
                                    place?.geometry?.location?.lat() || {};
                                const lng =
                                    place?.geometry?.location?.lng() || {};
                                onLocationSelection(
                                    place.address_components,
                                    lat,
                                    lng
                                );
                            }}
                            placeholder={
                                translation['enter_your_delivery_location']
                            }
                            options={{
                                types: ['establishment'],
                                componentRestrictions: { country: 'qa' },
                            }}
                        />
                    </div>
                    <div className='mb-2'>
                        <div className='flex'>
                            {translation['address']}
                            <div className='text-red-800  text-lg'> *</div>
                        </div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={`${translation['enter_full_address']} *`}
                            onChange={(e) =>
                                inputChangeHandler(
                                    'completeAddress',
                                    e.target.value
                                )
                            }
                        />
                    </div>

                    <div className='mb-2'>
                        <div className='flex'>
                            {translation['building_No']}
                            <div className='text-red-800  text-lg'> *</div>
                        </div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={`${translation['building_No']} *`}
                            onChange={(e) =>
                                inputChangeHandler('buildingNo', e.target.value)
                            }
                        />
                    </div>

                    <div className='mb-2'>
                        <div className='flex'>
                            {translation['street_No']}
                            <div className='text-red-800  text-lg'> *</div>
                        </div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={translation['street_No']}
                            onChange={(e) =>
                                inputChangeHandler('streetNo', e.target.value)
                            }
                        />
                    </div>
                    <div className='mb-2'>
                        <div className='flex'>
                            {translation['zone_No']}
                            <div className='text-red-800  text-lg'> *</div>
                        </div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={`${translation['zone_No']} *`}
                            onChange={(e) =>
                                inputChangeHandler('zoneNo', e.target.value)
                            }
                        />
                    </div>
                    <div className='mb-2'>
                        <div className='flex'>{translation['area_Name']}</div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={`${translation['area_Name']}`}
                            onChange={(e) =>
                                inputChangeHandler('areaName', e.target.value)
                            }
                        />
                    </div>
                    <div className='mb-2'>
                        <div className='flex'>
                            {translation['contact_Number']}
                        </div>
                        <Input
                            className='h-11 border rounded-lg border-gray-300 p-3 mt-2 bg-white'
                            style={{ textAlignVertical: 'top' }}
                            placeholder={translation['contact_Number']}
                            onChange={(e) =>
                                inputChangeHandler('contact_no', e.target.value)
                            }
                        />
                    </div>
                    <div className='flex flex-row justify-center items-center my-4'>
                        <div
                            className='flex-row bg-gray-50 p-3 px-6 rounded-lg shadow '
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                            onClick={saveAddress}
                        >
                            <div className='text-sm font-medium text-white '>
                                {translation['save_Address']}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewAddressScreen;
