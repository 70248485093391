import React, { useState } from 'react';
import { themeColors } from '../theme';
import { UserOutlined } from '@ant-design/icons';
import { LAST_PAGE_VISIT, SERVICETYPE } from '../services/Typecode';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import { Drawer } from 'antd';
import DeliveryIcon from '../assets/delivery2.png';
import TakeAwayIcon from '../assets/takeaway.png';
import Language from '../components/Language';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
const Onboarding = () => {
    const logGAEvent = useAnalyticsEventTracker('OnBoardingScreen');
    const navigate = useNavigate();
    const [profileModal, setProfileModal] = useState(false);
    const { authState, logOutClickHandler } = useAuth();

    const { translation } = useLanguage();

    const servicetypeClickHandler = (type) => {
        logGAEvent('service_type_selection', `User selected ${type}`);
        localStorage.setItem(SERVICETYPE, type);
        if (type === 'takeaway') {
            navigate('/menulist');
        } else {
            navigate('/home');
        }
    };
    const storeLastLocation = async (pageName = '', cb) => {
        try {
            await localStorage.setItem(LAST_PAGE_VISIT, pageName);
            if (cb) cb();
        } catch (error) {}
    };
    const orderHistoryClickHandler = (e) => {
        logGAEvent('order_history_click', 'User clicked the order history button');
        if (authState.authenticated) {
            navigate('/orderhistory');
        } else {
            storeLastLocation('orderhistory');
            navigate('/login');
        }
    };
    const loginClickHandler = (e) => {
        logGAEvent('login_click', 'User clicked the login button');
        // storeLastLocation('home');
        navigate('/login');
    };
    const logout = () => {
        logGAEvent('logout_click', 'User clicked the logout button');
        logOutClickHandler();
        setProfileModal(false);
    };

    return (
        <div
            className='flex flex-1 flex-col px-4 justify-around items-center'
            style={{
                backgroundColor: themeColors.primaryColor,
                minHeight: '100vh',
            }}
        >
            <div className='w-full flex flex-row justify-between items-center px-4 pt-4 absolute top-0'>
                <Language />
                <div
                    className='flex w-8 h-8 rounded-full justify-center items-center p-2 '
                    onClick={() => setProfileModal(true)}
                    style={{
                        borderWidth: 1,
                        borderColor: 'white',
                    }}
                >
                    <UserOutlined
                        style={{
                            color: 'white',
                        }}
                    />
                </div>
            </div>
            <div className='flex justify-center items-center flex-col gap-5'>
                <img
                    className='max-w-52'
                    src='https://master-vserve.s3.ap-south-1.amazonaws.com/property/1694454283-home_logo-Pure white.png'
                    alt='logo'
                />
                <div className='text-white text-sm'>
                    {translation['deliveryType']}
                </div>
            </div>
            <div className='flex flex-col w-full gap-8 justify-center items-center '>
                <div
                    className='flex rounded-2xl flex-col justify-center items-center h-28 gap-4 w-full shadow-lg'
                    onClick={() => servicetypeClickHandler('takeaway')}
                    style={{
                        backgroundImage:
                            'linear-gradient(rgb(237 237 237), rgb(170 137 208))',
                    }}
                >
                    <div
                        className='text-md text-white font-bold'
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['takeaway']}
                    </div>
                    <img
                        src={TakeAwayIcon}
                        className='w-10 h-10'
                        alt='takeaway'
                    />
                </div>
                <div
                    className='flex rounded-2xl flex-col justify-center items-center h-28 gap-4 w-full shadow-lg'
                    onClick={() => servicetypeClickHandler('delivery')}
                    style={{
                        backgroundImage:
                            'linear-gradient(rgb(237 237 237), rgb(170 137 208))',
                    }}
                >
                    <div
                        className='text-md text-white font-bold'
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['delivery']}
                    </div>
                    <img
                        src={DeliveryIcon}
                        className='w-10 h-10'
                        alt='delivery'
                    />
                </div>
            </div>

            <Drawer
                placement={'bottom'}
                closable={false}
                onClose={() => setProfileModal(false)}
                open={profileModal}
            >
                <div className=' bg-white rounded-lg flex flex-col gap-2'>
                    <div
                        className='flex flex-row justify-start items-center p-3 rounded-xl bg-slate-200'
                        onClick={orderHistoryClickHandler}
                    >
                        <div className='text-sm font-medium text-black	'>
                            {translation['yourOrders']}
                        </div>
                    </div>
                    {authState.authenticated ? (
                        <div
                            className='flex flex-row justify-start items-center  p-3 rounded-xl bg-slate-200'
                            onClick={logout}
                        >
                            <div className='text-sm font-medium	text-black'>
                                {translation['logout']}
                            </div>
                        </div>
                    ) : (
                        <div
                            className='flex flex-row justify-start items-center  p-3 rounded-xl bg-slate-200'
                            onClick={loginClickHandler}
                        >
                            <div className='text-sm font-medium	text-black'>
                                {translation['login']}
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default Onboarding;
