import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import { themeColors } from '../theme';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const DisclaimerComponent = (props) => {
    const { message, title, dark } = props;
    const [disclaimerModal, setDisclaimerModal] = useState(false);
    const logGAEvent = useAnalyticsEventTracker('disclaimer');

    const diclaimerIconOpenHandler = () => {
        setDisclaimerModal(true);
        logGAEvent('disclaimer_icon_clicked', 'open');
    };
    const diclaimerIconCloseHandler = () => {
        setDisclaimerModal(false);
        logGAEvent('disclaimer_icon_clicked', 'close');
    };

    let bgColor = dark ? themeColors.primaryColor : '#fff';
    let iconColor = dark ? '#fff' : themeColors.primaryColor;
    return (
        <>
            <Button
                className='flex h-8 w-8 rounded-full justify-center items-center px-3 py-0.5 bg-white border-none shadow-lg'
                onClick={diclaimerIconOpenHandler}
                style={{
                    backgroundColor: bgColor,
                }}
            >
                <InfoCircleOutlined
                    style={{
                        color: iconColor,
                    }}
                />
            </Button>
            <Drawer
                title={title}
                placement={'bottom'}
                closable={false}
                onClose={diclaimerIconCloseHandler}
                open={disclaimerModal}
            >
                <p
                    className='text-xs text-gray-400 font-normal flex-wrap'
                    dangerouslySetInnerHTML={{ __html: message }}
                ></p>
            </Drawer>
        </>
    );
};

export default DisclaimerComponent;
